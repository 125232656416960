@import '../../theme/spacing';
@import '../../theme/mediaqueries';
@import '../../theme/font-weight';

.li {
  position: relative;
  padding: 0.375rem 0 0.375rem 1.125rem;

  @media (min-width: $mq-desktop) {
    padding: $spacing-xxs 0 $spacing-xxs $spacing-s;
  }

  &::marker {
    font-weight: $font-weight-bold;
  }

  &::before {
    display: inline-block;
    position: absolute;
    right: calc(100% - 0.625rem);
    font-weight: $font-weight-bold;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.ol {
  list-style: none;
  counter-reset: item;

  .li {
    counter-increment: item;

    &::before {
      content: counter(item) '.';
    }
  }
}

.ul {
  list-style: none;

  .li {
    &::before {
      content: '\2022';
    }
  }

  .li .li {
    &::before {
      content: '\25e6';
    }
  }

  .li .li .li {
    &::before {
      content: '\2043';
    }
  }

  .li .li .li .li {
    &::before {
      content: '\2023';
    }
  }
}
