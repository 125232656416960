@import '../../../photon/theme/global';

.item {
  display: block;
  position: relative;
  padding: $spacing-xs;
  transition: background-color 0.1s;
  border: 1px solid $color-gray;
  border-radius: $border-radius-s;

  &:hover {
    background: color-mix(in srgb, $color-black 11%, transparent);
  }

  @include focus-ring;
}

.headline {
  display: block;
  margin-bottom: $spacing-xxs;
}

.meta-list {
  display: flex;
  color: $color-dark-gray;
  font-size: 0.75rem;
  line-height: 1.7;
  list-style: none;

  li:not(:first-child)::before {
    content: '|';
    margin: 0 $spacing-xs;
  }

  [target='_blank'] &::after {
    content: '\2197';
    position: absolute;
    right: $spacing-xs;
    color: $color-dark-gray;
    font-size: 0.75rem;
  }
}
