@use 'sass:math';
@import '../../../photon/theme/global';

.section {
  @include content-container;

  display: flex;
  flex-direction: column;
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;

  @media (min-width: $mq-medium-and-above) {
    align-items: center;
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;

    > * {
      width: math.div(8, 12) * 100%;
    }
  }
}

.hr {
  margin: $spacing-s $spacing-m;
  border: 1px solid $color-gray-200;

  @media (min-width: $mq-medium-and-above) {
    width: math.div(6, 12) * 100%;
    margin: $spacing-s 0;
  }
}
