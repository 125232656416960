@import '../../../../packages/photon/theme/global';

.mobile {
  &-is-background {
    width: 100%;
    height: 100%;
  }

  &-is-hidden-on-desktop {
    @media (min-width: $mq-medium-and-above) {
      display: none;
    }
  }
}

.desktop {
  display: none;

  &-is-background {
    width: 100%;
    height: 100%;
  }

  @media (min-width: $mq-medium-and-above) {
    display: block;
  }
}
