@import '../../../photon/theme/global';

.container {
  text-align: center;

  @include content-container;
}

.loader {
  margin: $spacing-xl 0;
}

.form:has(heyflow-wrapper[index]) {
  padding-bottom: $spacing-xl;

  & + .loader {
    display: none;
  }
}
