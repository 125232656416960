@import '../../../photon/theme/global';

.stage {
  display: grid;
  grid-template-areas: 'media' 'text-intro' 'app-bagdes';
  grid-template-columns: 1fr;
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;
  gap: $spacing-xs;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    grid-template: 1fr min-content min-content 1fr / 2fr 1fr;
    grid-template-areas: '. media' 'text-intro media' 'app-bagdes media' '. media';
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }
}

.media {
  grid-area: media;
}

.text-intro {
  grid-area: text-intro;

  @media (min-width: $mq-medium-and-above) {
    max-width: calc(100% / 12 * 11 - $spacing-m);
  }
}

.app-badges {
  grid-area: app-badges;
}
