@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    & > * {
      margin-right: math.div(100%, 12) * 2;
      margin-left: math.div(100%, 12) * 2;
    }
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }
  }

  @include padding-classes;
}

.headline {
  &.headline:has(+ a) {
    @media (min-width: $mq-medium-and-above) {
      margin-bottom: $spacing-s;
    }
  }

  &:not(:last-child) {
    margin-bottom: $spacing-xs;

    @media (min-width: $mq-medium-and-above) {
      margin-bottom: $spacing-xxs;
    }
  }
}

.text {
  &:not(:last-child) {
    margin-bottom: $spacing-xs;

    @media (min-width: $mq-medium-and-above) {
      margin-bottom: $spacing-s;
    }
  }
}

.image {
  align-self: center;
  max-width: 4rem;
  margin-bottom: $spacing-xs;
}

.app-badges {
  justify-content: center;
}
