@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.text-intro {
  margin-bottom: $spacing-s;

  @media (min-width: $mq-medium-and-above) {
    margin-right: math.div(100%, 12) * 2;
    margin-bottom: $spacing-m;
    margin-left: math.div(100%, 12) * 2;
  }
}

.teaser-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  list-style: none;

  @media (min-width: $mq-medium-and-above) {
    margin-right: math.div(100%, 12) * 2;
    margin-left: math.div(100%, 12) * 2;
  }
}

.cta.cta {
  display: block;
  justify-self: center;
  margin-top: $spacing-s;
}
