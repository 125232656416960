@import '../../../photon/theme/global';

.privacy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  padding: $spacing-xs $spacing-xxs;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
}

.headline {
  margin-bottom: $spacing-xs;
}

.text {
  margin-bottom: $spacing-xs;
}
