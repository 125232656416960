@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    > * {
      margin-right: math.div(100%, 12) * 2;
      margin-left: math.div(100%, 12) * 2;
    }
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }

  @include padding-classes;
}

.headline {
  margin-bottom: $spacing-xxs;

  @media (min-width: $mq-small-and-above) {
    margin-bottom: $spacing-xs;
  }
}

.list {
  &:not(:last-child) {
    margin-bottom: $spacing-xs;
  }
}

.text {
  &:not(:last-child) {
    margin-bottom: $spacing-xs;
  }
}
