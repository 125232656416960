@import '../../../photon/theme/global';

.section {
  @include content-container;

  &-content {
    border-bottom: 1px solid rgba($color-gray, 0.2);
    background-color: $color-white;
    color: $color-black;
  }

  &-footer {
    border-bottom: 1px solid rgba($color-white, 0.2);
    background-color: $color-black;
    color: $color-white;
  }
}

.nav {
  position: relative;
  overflow: hidden;

  .section-footer > &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    height: 100%;
    background: linear-gradient(270deg, $color-black 0%, rgba($color-black, 0) 100%);
    pointer-events: none;
  }
}

.list {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  list-style: none;

  li {
    display: flex;
    align-items: center;

    &:last-child {
      padding-right: 5rem;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.chevron {
  margin: 0 $spacing-xxs;
  color: $color-dark-gray;
}

.link {
  margin: $spacing-xxs 0;
  padding: $spacing-3xs 0;
  color: inherit;
  font-size: 0.625rem;
  white-space: nowrap;

  @include focus-ring;

  @media (min-width: $mq-medium-and-above) {
    margin: $spacing-s 0;
    padding: 0;
    font-size: 0.75rem;
  }

  &:hover {
    transition: $duration-large-outro;
    opacity: 0.6;
  }

  li:last-child > & {
    font-weight: $font-weight-bold;
  }
}
