@import '../../../../packages/photon/theme/global';

.h6 {
  margin: 0.5rem 0 0;
  color: $color-dark-gray;
  font-size: 0.75rem;
}

.text,
.headline {
  margin-bottom: $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: $spacing-xs;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: $spacing-xs;
  }
}
