@import '../../../photon/theme/global';
@import '../../../photon/atoms/box-cta/mixins';

.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-xs $spacing-s;
  border-radius: $border-radius-s;
  /* stylelint-disable-next-line meowtec/no-px */
  box-shadow: 0 4px 4px rgba($color-black, 0.2);
  gap: $spacing-xs;

  @media (max-width: $mq-below-small) {
    padding: $spacing-xs;
  }

  @each $color-name, $color-value in $colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  gap: $spacing-xs;

  @media (max-width: $mq-below-small) {
    gap: $spacing-xxs;
  }
}

.icon {
  @media (max-width: $mq-below-small) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.cta {
  @media (max-width: $mq-below-small) {
    @include box-cta-small;
  }
}
