$color-orange-100: #fff5ea;
$color-orange-200: #ffddba;
$color-orange-300: #fcbc67;
$color-orange-400: #ffa137;
$color-orange-500: #db7922;
$color-orange-600: #a14d0f;
$color-orange-700: #783608;
$color-orange-800: #512508;
$color-orange-900: #391a06;

$color-red-100: #fff2f2;
$color-red-200: #fcc8c6;
$color-red-300: #f89d99;
$color-red-400: #f47771;
$color-red-500: #ea3b32;
$color-red-600: #b9312a;
$color-red-700: #982b23;
$color-red-800: #661a12;
$color-red-900: #47120d;

$color-berry-100: #fcf6fa;
$color-berry-200: #f9e3ea;
$color-berry-300: #f0baca;
$color-berry-400: #e398ac;
$color-berry-500: #e17895;
$color-berry-600: #b84163;
$color-berry-700: #9c2d4d;
$color-berry-800: #820e38;
$color-berry-900: #5b0a27;

$color-aqua-100: #ecfcff;
$color-aqua-200: #cef0f8;
$color-aqua-300: #8dd7ea;
$color-aqua-400: #60bad3;
$color-aqua-500: #4b97b6;
$color-aqua-600: #2a6d8f;
$color-aqua-700: #084465;
$color-aqua-800: #012d4e;
$color-aqua-900: #012037;

$color-smoke-100: #f4f6f9;
$color-smoke-200: #dce3eb;
$color-smoke-300: #b9c8d7;
$color-smoke-400: #96acc3;
$color-smoke-500: #758ea5;
$color-smoke-600: #4c6278;
$color-smoke-700: #2d3d4b;
$color-smoke-800: #19212a;
$color-smoke-900: #11181d;

$color-gray-100: #f6f6f6;
$color-gray-200: #e2e2e2;
$color-gray-300: #c6c6c6;
$color-gray-400: #ababab;
$color-gray-500: #888888;
$color-gray-600: #616161;
$color-gray-700: #3b3b3b;
$color-gray-800: #181818;
$color-gray-900: #111111;

$color-green-100: #eefaf4;
$color-green-200: #c5edd8;
$color-green-300: #8bdbb0;
$color-green-400: #65c293;
$color-green-500: #529d77;
$color-green-600: #367056;
$color-green-700: #1a4837;
$color-green-800: #142a20;
$color-green-900: #0e1d16;

$color-moss-100: #f1f7f5;
$color-moss-200: #d6e5e1;
$color-moss-300: #acccc4;
$color-moss-400: #85b4a9;
$color-moss-500: #61847f;
$color-moss-600: #496463;
$color-moss-700: #334747;
$color-moss-800: #1c2723;
$color-moss-900: #141c18;

$color-sand-100: #f8f6f2;
$color-sand-200: #e9e2d7;
$color-sand-300: #d2c5b1;
$color-sand-400: #b7aa95;
$color-sand-500: #968a7a;
$color-sand-600: #686256;
$color-sand-700: #504b43;
$color-sand-800: #2c2a25;
$color-sand-900: #201d1a;

$color-white: #ffffff;
$color-black: #000000;

/* stylelint-disable */
:export {
  Orange100: $color-orange-100;
  Orange200: $color-orange-200;
  Orange300: $color-orange-300;
  Orange400: $color-orange-400;
  Orange500: $color-orange-500;
  Orange600: $color-orange-600;
  Orange700: $color-orange-700;
  Orange800: $color-orange-800;
  Orange900: $color-orange-900;
  Red100: $color-red-100;
  Red200: $color-red-200;
  Red300: $color-red-300;
  Red400: $color-red-400;
  Red500: $color-red-500;
  Red600: $color-red-600;
  Red700: $color-red-700;
  Red800: $color-red-800;
  Red900: $color-red-900;
  Berry100: $color-berry-100;
  Berry200: $color-berry-200;
  Berry300: $color-berry-300;
  Berry400: $color-berry-400;
  Berry500: $color-berry-500;
  Berry600: $color-berry-600;
  Berry700: $color-berry-700;
  Berry800: $color-berry-800;
  Berry900: $color-berry-900;
  Aqua100: $color-aqua-100;
  Aqua200: $color-aqua-200;
  Aqua300: $color-aqua-300;
  Aqua400: $color-aqua-400;
  Aqua500: $color-aqua-500;
  Aqua600: $color-aqua-600;
  Aqua700: $color-aqua-700;
  Aqua800: $color-aqua-800;
  Aqua900: $color-aqua-900;
  Smoke100: $color-smoke-100;
  Smoke200: $color-smoke-200;
  Smoke300: $color-smoke-300;
  Smoke400: $color-smoke-400;
  Smoke500: $color-smoke-500;
  Smoke600: $color-smoke-600;
  Smoke700: $color-smoke-700;
  Smoke800: $color-smoke-800;
  Smoke900: $color-smoke-900;
  Gray100: $color-gray-100;
  Gray200: $color-gray-200;
  Gray300: $color-gray-300;
  Gray400: $color-gray-400;
  Gray500: $color-gray-500;
  Gray600: $color-gray-600;
  Gray700: $color-gray-700;
  Gray800: $color-gray-800;
  Gray900: $color-gray-900;
  Green100: $color-green-100;
  Green200: $color-green-200;
  Green300: $color-green-300;
  Green400: $color-green-400;
  Green500: $color-green-500;
  Green600: $color-green-600;
  Green700: $color-green-700;
  Green800: $color-green-800;
  Green900: $color-green-900;
  Moss100: $color-moss-100;
  Moss200: $color-moss-200;
  Moss300: $color-moss-300;
  Moss400: $color-moss-400;
  Moss500: $color-moss-500;
  Moss600: $color-moss-600;
  Moss700: $color-moss-700;
  Moss800: $color-moss-800;
  Moss900: $color-moss-900;
  Sand100: $color-sand-100;
  Sand200: $color-sand-200;
  Sand300: $color-sand-300;
  Sand400: $color-sand-400;
  Sand500: $color-sand-500;
  Sand600: $color-sand-600;
  Sand700: $color-sand-700;
  Sand800: $color-sand-800;
  Sand900: $color-sand-900;
  White: $color-white;
  Black: $color-black;
}
